import { FragmentType, getFragmentData, graphql } from '~/resources/graphql'
import { computed, inject, InjectionKey, provide, Ref } from 'vue'
import { UseStorefrontStorefrontFragmentFragment } from '~/resources/graphql/graphql'

// NOTE: if you change this fragment you will have to change GroupLayoutWrapper.vue
// That is where a storefront is being made for group mode
export const UseStorefrontStorefrontFragment = graphql(`
    fragment UseStorefrontStorefrontFragment on Storefront {
        treeNavigation
        collectMoney
        profileAllowed
        allowGuestCheckout
        idleTimeout
        idleTimeoutMessage
        config(language: ENGLISH)
    }
`)

const useStorefrontInjectionKey: InjectionKey<
    Ref<UseStorefrontStorefrontFragmentFragment>
> = Symbol('UseStorefrontInjectionKey')

export function createStorefront(
    storefront: Ref<FragmentType<typeof UseStorefrontStorefrontFragment>>
) {
    const storefrontData = computed(() =>
        getFragmentData(UseStorefrontStorefrontFragment, storefront.value)
    )
    provideStorefront(storefrontData)
    return storefrontData
}

export function provideStorefront(
    storefrontData: Ref<UseStorefrontStorefrontFragmentFragment>
) {
    provide(useStorefrontInjectionKey, storefrontData)
}

export function useStorefront() {
    const result = inject(useStorefrontInjectionKey)
    if (result === undefined) {
        throw new Error()
    }

    return result
}
